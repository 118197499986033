import React, { Component } from "react"
const isServer = typeof window === "undefined"

export default class ThemeToggle extends Component {
  state = {
    theme: !isServer && localStorage.getItem("theme"),
  }

  componentDidMount = () => {
    if (this.state.theme === null) {
      localStorage.setItem("theme", "light")
      this.setState({ theme: "light" })
    }
    document.querySelector("body").setAttribute("theme", this.state.theme)
  }

  componentDidUpdate = () => {
    document.querySelector("body").setAttribute("theme", this.state.theme)
  }

  toggleTheme = () => {
    if (window) {
      if (this.state.theme === "light") {
        localStorage.setItem("theme", "dark")
        this.setState({ theme: "dark" })
        return
      }
      localStorage.setItem("theme", "light")
      this.setState({ theme: "light" })
    }
  }

  render() {
    return (
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="switch"
          onClick={this.toggleTheme}
          onChange={e => {}}
          checked={this.state.theme === "dark"}
        />
        <label htmlFor="switch">Toggle</label>
      </div>
    )
  }
}
